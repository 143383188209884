// 基准大小
const baseSize = 16;
function setRem() {
  const scale = document.documentElement.clientWidth / 1920;
  console.log(baseSize * Math.min(scale, 2));
  // let fontSize =
  //   baseSize * Math.min(scale, 2) > 10.6 ? baseSize * Math.min(scale, 2) : 10.6;
  let fontSize = baseSize * Math.min(scale, 2);
  document.documentElement.style.fontSize = fontSize + "px";
}
// font-size:100px;,那1rem等于100px
//初始化
setRem();
window.onresize = function () {
  setRem();
};
