import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueFullPage from 'vue-fullpage.js'
// import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css";
import "./assets/styles/font/font.css";
import { Button, Form, FormItem, Input ,Image} from "element-ui";
import animated from 'animate.css';
Vue.use(animated);
Vue.use(VueFullPage);
import "@/styles/index.scss";
import "normalize.css";
import "@/utils/rem";
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Image);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
