import Vue from "vue";
import Router from "vue-router";
import Nprogress from "nprogress";
import "nprogress/nprogress.css"; // progress bar style
import Layout from "@/layout/index.vue";
Vue.use(Router);
// Nprogress.inc(0.2);
// Nprogress.configure({ easing: "ease", speed: 500, showSpinner: false });
const routes = [
  {
    path: "/",
    redirect: "/index",
    component: Layout,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@page/index"),
        meta: { title: "首页"}
      },
      {
        path: "/product",
        name: "product",
        component: () => import("@page/product"),
        meta: { title: "产品中心" }
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@page/solution"),
        meta: { title: "解决方案"}
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@page/aboutUs"),
        meta: { title: "关于我们" }
      },
      {
        path: "/experience",
        name: "experience",
        component: () => import("@page/experience"),
        meta: { title: "使用体验"}
      }
    ]
  },

  {
    path: "/*",
    name: "error-404",
    meta: { title: "404-页面不存在" },
    component: () => import("@page/404.vue")
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 })
});
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
//重置路由
export function resetRouter() {
  const newRouter = new Router();
  router.matcher = newRouter.matcher;
}

export default router;
