<template>
  <footer>
    <div class="wrapper-fcb">
      <div class="footer-service">
        <h4>电话</h4>
        <p class="info">027-87675180</p>
        <h4>地址</h4>
        <p class="info">武汉市洪山区五环天地2005室</p>
        <div class="code">
          <img src="@/assets/code.png" />
          <p>扫码关注公众号</p>
        </div>
      </div>
      <div class="siteinfo clearfix">
        <div class="footer-link">
          <h4 @click="handc">产品中心</h4>
          <a v-for="i in productLink" :key="i.label" :href="i.url">{{
            i.label
          }}</a>
        </div>
        <div class="footer-link">
          <h4>解决方案</h4>
          <a v-for="i in businessLink" :key="i.label" :href="i.url">{{
            i.label
          }}</a>
        </div>
        <div class="footer-link">
          <h4>关于我们</h4>
          <a v-for="i in aboutLink" :key="i.label" :href="i.url">{{
            i.label
          }}</a>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
        <a style="color: white" href="https://www.miit.gov.cn">武ICP备11007946号Copyright @武汉枫林云科技有限公司. All Rights Reserved </a>
    </div>
  </footer>
</template>

<script>
import { BusEmit } from "@/utils/eventBus.js";

export default {
  methods: {
    handc() {
      BusEmit("Top", "setBlackBg");
    }
  },
  data() {
    return {
      productLink: [
        { label: "线上售楼处", url: "/product" },
        { label: "全民营销", url: "/product" },
        { label: "中介分销", url: "/product" },
        { label: "自拓行销", url: "/product" },
        { label: "来访接待台", url: "/product" },
        { label: "移动案场", url: "/product" },
        { label: "业务中台", url: "/product" }
      ],
      businessLink: [
        { label: "应用前台", url: "/solution" },
        { label: "业务中台", url: "/solution" },
        { label: "数据中台", url: "/solution" },
        { label: "服务中台", url: "/solution" }
      ],
      aboutLink: [
        { label: "公司介绍", url: "/aboutUs" },
        { label: "荣誉资历", url: "/aboutUs" },
        { label: "合作伙伴", url: "/aboutUs" },
        { label: "研发实力", url: "/aboutUs" }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
footer {
  position: relative;
  width: 100%;
  height: 940px;
  padding-top: 60px;
  background: url("~@/assets/footer-bg.png") center center;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  h4 {
    color: $blue;
    margin-bottom: 60px;
  }
  .info {
    font-size: 16px;
    margin-bottom: 80px;
  }
  .code {
    text-align: center;
    img {
      width: 208px;
      height: 208px;
    }
    p {
      font-size: 16px;
    }
  }
}
.footer-service {
  text-align: left;
}
.footer-link {
  float: left;
  display: flex;
  flex-direction: column;
  margin-left: 200px;

  a {
    text-decoration: none;
    margin-bottom: 40px;
    color: #fff;
    transition: all 0.4s;
    &:hover {
      color: $blue;
    }
  }
}
.footer-copyright {
  position: absolute;
  font-size: 16px;
  bottom: 56px;
  margin-left: 50px;
  text-align: center;
  width: 100%;
}
</style>
