<template>
  <div>
    <Top></Top>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Top from "./components/top.vue";
import Footer from "./components/footer.vue";
export default {
  components: {
    Top,
    Footer
  }
};
</script>
