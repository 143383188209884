<template>
  <div>
    <header class="fixed">
      <div class="wrapper-fcb">
        <div class="logo-wp">
          <a href="/index" class="logo"><img src="@/assets/logo.png" /></a>
          <span class="slogan">房地产数字化营销引领者</span>
        </div>
        <div class="nav-list">
          <a v-for="i in navData" :key="i.url" :href="i.url" :class="i.url === path ? 'active a-btn' : 'a-btn'">{{ i.label }}</a>
          <router-link to="/experience" class="top-btn">试用体验</router-link>
        </div>
      </div>
    </header>
    <div class="sidebar">
      <div class="sidebar1 flex" @mouseenter="qrCode = true" @mouseleave="qrCode = false">
        <img :src="qrCode ? sidebar11 : sidebar1" />
        <img v-if="qrCode" class="qrCode" src="../../assets/images/qrCode.png" alt="" />
      </div>
      <div class="sidebar2 flex" @mouseenter="phone = true" @mouseleave="phone = false">
        <img :src="phone ? sidebar22 : sidebar2" />
        <img v-if="phone" class="phone" src="../../assets/images/phone.png" alt="" />
        <p>电话联系</p>
      </div>
      <div class="sidebar3 flex" @mouseenter="product = true" @mouseleave="product = false">
        <img :src="product ? sidebar33 : sidebar3" />
        <p>产品试用</p>
      </div>
      <div class="sidebar4 flex" @click="backTop">
        <img src="../../assets/images/sidebar4.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { BusOn } from '@/utils/eventBus.js'

export default {
  name: 'Top',
  mixins: [BusOn],
  data() {
    return {
      phone: false,
      qrCode: false,
      product: false,
      fixedTop: false,
      path: '',
      sidebar1: require('../../assets/images/sidebar1.png'),
      sidebar2: require('../../assets/images/sidebar2.png'),
      sidebar3: require('../../assets/images/sidebar3.png'),
      sidebar11: require('../../assets/images/sidebar11.png'),
      sidebar22: require('../../assets/images/sidebar22.png'),
      sidebar33: require('../../assets/images/sidebar33.png'),
      navData: [
        { label: '首页', url: '/index' },
        { label: '产品中心', url: '/product' },
        { label: '解决方案', url: '/solution' },
        { label: '关于我们', url: '/aboutUs' }
      ]
    }
  },
  mounted() {
    const path = this.$route.path
    this.path = path
    window.addEventListener('scroll', this.scrollToTop)

  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // 头部白透明背景
    // setWhiteBg() {
    //   var fcb = document.querySelector("header");
    //   fcb.classList.remove("hight");
    // },
    // setBlackBg() {
    //   var fcb = document.querySelector("header");
    //   fcb.classList.add("hight");
    // }
       // 点击卡片回到顶部方法，加计时器是为了过渡顺滑
    backTop (e) {
      console.log(e.path[6]);
      if(e.path[6].classList[0]){
        // e.path[6].classList[0] = "fp-viewing-0"
        // e.path[6].classList.value = "fp-viewing-0"
      }
      const that = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      const that = this
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
        that.scrollTop = scrollTop
      if (that.scrollTop > 60) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
}
header {
  height: 60px;
  background: rgba(0, 0, 0, 0.4);
  // -webkit-backdrop-filter: blur(5px);
  // backdrop-filter: blur(5px);
  transition: all 0.4s;
  .wrapper-fcb {
    height: 60px;
  }
  .logo-wp {
    display: flex;
    align-items: center;
    height: 60px;
  }
  .logo img {
    display: inline-block;
    width: 114px;
    height: 40px;
  }
  .slogan {
    display: inline-block;
    border-left: 1px solid #fff;
    height: 22px;
    padding-left: 20px;
    margin-left: 20px;
    color: $white;
    font-size: 20px;
    font-weight: bold;
  }
}
.nav-list {
  // color: $blue;
  a.a-btn {
    position: relative;
    display: inline-block;
    color: $white;
    margin: 0 30px;
    text-decoration: none;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    &:hover,
    &.active {
      color: $blue;
      &::before {
        top: 0;
        @include opacity(1);
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 36px;
      height: 4px;
      left: 50%;
      top: 10px;
      @include opacity(0);
      margin-left: -18px;
      background: $blue;
      transition: all 0.4s;
    }
  }
  .top-btn {
    display: inline-block;
    width: 130px;
    height: 36px;
    text-decoration: none;
    line-height: 36px;
    background: $blue;
    border-radius: 6px;
    font-size: 16px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s;
    text-align: center;
    &:hover {
      background: $blue_hover;
    }
  }
}
.hight {
  background: hsla(0, 0%, 1%, 0.4);
}
.sidebar {
  position: fixed;
  z-index: 999;
  width: 50px;
  right: 60px;
  bottom: 66px;
  text-align: center;
  .sidebar1,
  .sidebar2,
  .sidebar3 {
    background-color: #fff;
    box-shadow:0px 0px 6px #00000030;
    cursor: pointer;
  }
  .sidebar1:hover,
  .sidebar2:hover,
  .sidebar3:hover {
    box-sizing: border-box;
    background-color: $blue;
    border: 2px solid #fff;
    color: #fff;
  }
  .sidebar1 {
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 24px;
    .qrCode {
      position: absolute;
      right: 60px;
      top: -56px;
    }
  }
  .sidebar2 {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    box-sizing: border-box;
    width: 50px;
    height: 160px;
    border-radius: 25px;
    color: $blue;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
    .phone {
      position: absolute;
      right: 60px;
      top: 120px;
    }
    p {
      margin: 0;
      width: 30px;
    }
  }

  .sidebar3 {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    box-sizing: border-box;
    width: 50px;
    height: 160px;
    border-radius: 25px;
    color: $blue;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
    p {
      margin: 0;
      width: 30px;
    }
  }
  .sidebar4 {
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 50%;
    background-color: $blue;
    border: 2px solid #fff;
    cursor: pointer;
  }
}
</style>
