import Vue from "vue";
const Bus = new Vue();
export const BusEmit = (component, action, param) => {
  Bus.$emit(component, action, param);
};
export const BusOn = {
  mounted() {
    Bus.$on(`${this.$options.name}`, this.onBusAction);
  },
  beforeDestroy() {
    Bus.$off(`${this.$options.name}`, this.onBusAction);
  },
  methods: {
    onBusAction(action, param) {
      // log(`调用组件：${this.$options.name},方法：${action},参数：${param}`);
      this[action](param);
    }
  }
};
